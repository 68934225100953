import SegmentContainer from "@/components/segments/segment-container";
import PortableTextPlus from "@/components/portable-text-plus";
import Badge from "@/components/badge";
import classNames from "classnames";

export default function StackedListPanelTwoColumnBlocks({ segment, ...props }) {
  const borderColorHex = props.bgColorStyle.backgroundColor;
  return (
    <SegmentContainer
      className={props.paddingClasses}
      style={{ ...props.bgImageStyle, ...props.bgColorStyle }}
    >
      <div className={props.widthClasses}>
        {segment.heading && (
          <div className="mb-8 leading-snug font-semibold text-xl md:text-3xl">
            <PortableTextPlus value={segment.heading} />
          </div>
        )}
        <div className="relative">
          <div className="absolute w-full h-full bg-white opacity-70 rounded-xl"></div>
          <div className="p-4 sm:px-8">
            {segment.blocks.map((block, index) => {
              return (
                <div
                  key={block._key}
                  className={classNames(
                    "grid sm:grid-cols-2 gap-y-6 relative pt-6 pb-8",
                    index !== segment.blocks.length - 1 && "border-b"
                  )}
                  style={{ borderColor: borderColorHex }}
                >
                  <div className="flex sm:pr-8 lg:pr-16 leading-snug text-lg font-semibold whitespace-pre-line">
                    <div>
                      <PortableTextPlus value={block.heading} />
                    </div>
                  </div>
                  <div className="sm:pr-8 lg:pr-16 leading-snug prose">
                    <PortableTextPlus value={block.content} />
                    {block.badges && (
                      <div className="space-x-2">
                        {block.badges.map((badge) => (
                          <Badge key={badge.title} {...badge} />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </SegmentContainer>
  );
}
